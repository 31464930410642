import React, { useRef, useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';

import Dashboard from './components/Dashboard';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import EncargadoRegistroDocumento from './components/encargado/EncargadoRegistroDocumento';
import estudianteDocumento from './components/estudiante/estudianteDocumento';
import EncargadoListarDocumentos from "./components/encargado/EncargadoListarDocumentos";
import ListarDocumento from "./components/estudiante/EstudianteListarDocumento";
import EncargadoEditarDocumento from "./components/encargado/EncargadoEditarDocumento";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = () => {

    const [layoutMode, setLayoutMode] = useState('static');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [menuActive, setMenuActive] = useState(false);
    const [themeColor, setThemeColor] = useState('blue');
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [scheme, setScheme] = useState('light');
    const copyTooltipRef = useRef();
    const location = useLocation();

    let menuClick;
    let topbarItemClick;
    const crearMenu=()=> {
        const nombreRol = sessionStorage.getItem('tipo');
        if (nombreRol === 'Empleado') {
            return [
                {
                    label: 'Biblioteca Virtual', icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }
                    ]
                },
                {
                    label: 'Encargado', icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Registro proyecto', icon: 'pi pi-fw pi-book', to: '/EncargadoRegistroDocumento' },
                        { label: 'Listar proyectos', icon: 'pi pi-fw pi-list', to: '/EncargadoListarDocumentos' }
                    ]
                },
            ];
        }
        else {
            return [
                {
                    label: 'Biblioteca Virtual', icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }
                    ]
                },
                {
                    label: 'Estudiante', icon: 'pi pi-fw pi-home',
                    items: [
                        { label: 'Listar Documentos', icon: 'pi pi-fw pi-list', to: '/EstudianteListarDocumento' }
                    ]
                },
            ];
        }
    }
    const crearPath=()=>{
        const nombreRol = sessionStorage.getItem('tipo');
        var pathMenu="";
        if (nombreRol === 'Empleado') {
            pathMenu=
                <div className="layout-content-container">
                    <Route path="/" exact component={Dashboard}/>
                    <Route path="/EncargadoRegistroDocumento" component={EncargadoRegistroDocumento}/>
                    <Route path="/EncargadoListarDocumentos" component={EncargadoListarDocumentos}/>
                    <Route path="/EncargadoEditarDocumento" component={EncargadoEditarDocumento}/>
                </div>;
            return pathMenu;
        }
        else {
            pathMenu=
                <div className="layout-content-container">
                    <Route path="/" exact component={Dashboard}/>
                    <Route path="/estudianteDocumento" component={estudianteDocumento}/>
                    <Route path="/estudianteListarDocumento" component={ListarDocumento}/>
                </div>;
            return pathMenu;
        }
    }



    const menu=crearMenu();
    const pathMenus=crearPath();

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onMenuClick = (event) => {
        menuClick = true;
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (layoutMode === 'overlay' && !isMobile()) {
            setOverlayMenuActive(prevState => !prevState);
        } else {
            if (isDesktop())
                setStaticMenuDesktopInactive(prevState => !prevState);
            else
                setStaticMenuMobileActive(prevState => !prevState);
        }

        event.preventDefault();
    }

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevState => !prevState)
        hideOverlayMenu();
        event.preventDefault();
    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;

        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null);
        else
            setActiveTopbarItem(event.item);

        event.originalEvent.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive(prevState => !prevState);
    }

    const onDocumentClick = (event) => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null)
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }

            hideOverlayMenu();
        }

        topbarItemClick = false;
        menuClick = false;
    }

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuDesktopInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }
        else {
            return true;
        }
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false)
    }

    const isMobile = () => {
        return window.innerWidth < 1025;
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    }

    const isSlim = () => {
        return layoutMode === 'slim';
    }

    const changeMenuMode = (event) => {
        setLayoutMode(event.menuMode);
        setStaticMenuDesktopInactive(false);
        setOverlayMenuActive(false);
    }

    const onSchemeChange = (color) => {
        setScheme(color);
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        changeTheme(themeName + '-' + color);
        changeLogo(color);
    }

    const changeTheme = (theme) => {
        setThemeColor(theme.split('-')[0]);
        changeStyleSheetUrl('layout-css', theme, 'layout');
        changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    const onThemeChange = (theme) => {
        setThemeColor(theme)
        changeTheme(theme + '-' + scheme);
    }

    const changeStyleSheetUrl = (id, value, prefix) => {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    }

    const changeLogo = (scheme) => {
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const logoUrl = `assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.png`;

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    const layoutClassName = classNames('layout-wrapper', {
        'layout-horizontal': layoutMode === 'horizontal',
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-slim': layoutMode === 'slim',
        'layout-static-inactive': staticMenuDesktopInactive && layoutMode !== 'slim',
        'layout-mobile-active': staticMenuMobileActive,
        'layout-overlay-active': overlayMenuActive,
        'p-input-filled': inputStyle === 'filled'
    });

    const menuContainerClassName = classNames('layout-menu-container', { 'layout-menu-container-inactive': !isMenuVisible() })

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />


            <AppTopbar
                topbarMenuActive={topbarMenuActive} activeTopbarItem={activeTopbarItem}
                onMenuButtonClick={onMenuButtonClick}
                onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                onTopbarItemClick={onTopbarItemClick} />

            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-content">
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        layoutMode={layoutMode} active={menuActive} />
                    <div className="layout-menu-footer">
                        <div className="layout-menu-footer-title"></div>
                    </div>
                </div>
            </div>

            <div className="layout-content">
                <AppBreadcrumb />
                {pathMenus}
                <AppFooter />

                {staticMenuMobileActive && <div className="layout-mask"></div>}
            </div>
        </div>
    );

}

export default App;
