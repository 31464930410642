import React,{useState} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import API,{URLPDF} from '../service/apiRequest';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
export const Login = () => {
    const [userValue, setUserValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const history = useHistory();
    const goDashboard = () => {
        history.push('/');
    }
    const   inicioSesion = (usuario, clave) => {
        API.get(`usuario/inicio/${usuario}/${clave}`).then(resp => {
            if (resp.status===200) {
                sessionStorage.setItem('nombre',resp.data.nombre);
                sessionStorage.setItem('primerApellido',resp.data.primerApellido);
                sessionStorage.setItem('segundoApellido',resp.data.segundoApellido);
                sessionStorage.setItem('fechaNacimiento',resp.data.fechaNacimiento);
                sessionStorage.setItem('genero',resp.data.genero);
                sessionStorage.setItem('email',resp.data['email']);
                sessionStorage.setItem('tipo',resp.data.tipo);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Bienvenido al sistema',
                    showConfirmButton: false,
                    timer: 1500
                })
                goDashboard();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: resp.data.Mensaje,
                    footer: 'Revise bien los datos introducidos'
                });
            }

        }).catch(function (err) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Falla de conexión con la API',
                footer: 'Comuníquese con el desarrollador'
            });
        })
    }
    const recibirFormulario = (e) => {
        e.preventDefault();
        var usuario = userValue;
        var clave = passwordValue;
        if (typeof (Storage) !== 'undefined') {
            inicioSesion(usuario, clave);
        } else {
            // Código cuando Storage NO es compatible
        }
    }
        return (
            <div className="p-3">
                <div className="body-container">
                    <div className="grid">
                        <div className="col-12 lg:col-6 p-8 left-side"  style={{textAlign:"center"}}>
                                <img src="assets/img/logo.png" alt="apollo-layout" className="logo" width="50%"/>
                                <h1>Bienvenido</h1>
                                <p>
                                    Inicia sesión para entrar a la biblioteca maria cristina
                                </p>
                        </div>
                        <div className="col-12 lg:col-6 right-side">
                            <div className="login-wrapper" >
                                <form onSubmit={recibirFormulario}>
                                <div className="login-container">
                                    <span className="title text-2xl">Inicio</span>

                                    <div className="grid p-fluid">
                                        <div className="col-12">
                                            <InputText required placeholder="Nombre de usuario" value={userValue} onChange={(e) => setUserValue(e.target.value)}/>
                                        </div>
                                        <div className="col-12">
                                            <InputText required type="password" placeholder="Contraseña" value={passwordValue} onChange={(e) => setPasswordValue(e.target.value)}/>
                                        </div>
                                        <div className="col-6">
                                            <Button type="submit" label="Ingresar" icon="pi pi-check"
                                            />
                                        </div>
                                        <div className="col-6 password-container">
                                            <a rel="noreferrer" href={URLPDF+"restore/pin.xhtml"} target={"_blank"} type="text" className="p-link">Olvido la contraseña?</a>
                                        </div>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
};

