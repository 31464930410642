import React, {useState, useRef, useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {Panel} from 'primereact/panel';
import {Dropdown} from 'primereact/dropdown';
import {Toast} from "primereact/toast";
import {FileUpload} from "primereact/fileupload";
import API,{URLBAse} from "../../service/apiRequest";
import {MultiSelect} from "primereact/multiselect";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import Swal from "sweetalert2";

const EncargadoRegistroDocumento = () => {
    const [dropdownUsuario, setDropdownUsuario] = useState(null);
    const [dropdownCarrera, setDropdownCarrera] = useState(null);
    const [listCarrera, setListCarrera] = useState([]);
    const [listEstudiante, setListEstudiante] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [gestion, setGestion] = useState(null);
    const [modalidad, setModalidad] = useState('');
    const [tutor, setTutor] = useState('');
    const [chooseOptions, setChooseOptions] = useState({});
    const [dis, setDis] = useState(false);
    const [mensaje, setMensaje] = useState("Seleccione los documentos a cargar" +
        " y luego pulse el botón Subir.");
    const [dir] = useState(URLBAse+"proyecto/subir");
    const [uniqid,setUniqid] = useState("");
    const toast = useRef(null);
    const listarCarreras = () => {
        var list = [];
        API.get("carrera/listarCarrera").then(resp => {
            var data = resp.data;

            data.forEach(element => list.push({
                label: element[1],
                value: element[0]
            }));
        }).catch(function (err) {
        });
        setListCarrera(list);
    }
    const ListModalidadDefensa = [
        {label: 'PROYECTO DE GRADO', value: 0},
        {label: 'PROYECTO SOCIOCOMUNITARIO PRODUCTIVO', value: 1},
        {label: 'PROYECTO EMPRENDIMIENTO PRODUCTIVO', value: 2},
        {label: 'TRABAJO DIRIGIDO', value: 3},
    ];
    const listarEstudiante = () => {
        var list = [];
        API.get("estudiante/listarEstudiante").then(resp => {
            var data = resp.data;
            data.forEach(element => list.push({
                label: element[1],
                value: element[0]
            }));
        }).catch(function (err) {
        });
        setListEstudiante(list);
    }
    useEffect(() => {
        listarCarreras();
        listarEstudiante();

    }, []);
    const registrar = (e) => {
        e.preventDefault();
        if(!uniqid){
            toast.current.show({severity: 'error', summary: 'Error Mensaje', detail: 'falta subir el documento pdf'});
        } else {
            const data = {
                "titulo": titulo,
                "descripcion": descripcion,
                "modalidad":modalidad,
                "gestion":gestion,
                "usuario":dropdownUsuario,
                "carrera":dropdownCarrera,
                "tutor":tutor,
                "uniqid":uniqid,
            };
            API.post("proyecto/registrar",data).then(resp => {
                if(resp.status===200){
                    if (resp.data['error']==="1"){
                        Swal.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            text: resp.data['mensaje'],
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Exitoso',
                            text: resp.data['mensaje'],
                            willClose(popup) {
                                 window.location.reload();
                            }
                        });
                    }
                }else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Falla de conexión con la API',
                        footer: 'Comuníquese con el desarrollador'
                    });
                }
            })
        }
    }
    const onUpload = (e) => {
        if(e.xhr.status===200){
            var resp=JSON.parse(e.xhr.response);
            if(resp['su']==="1"){
                setMensaje("Documento subido correctamente termine de llenar el resto de los datos," +
                    " Si ya lo hizo presione en Registrar");
                setDis(true);
                setUniqid(resp['uniqid']);
                toast.current.show({severity: 'success', summary: 'Correcto Mensaje', detail: resp['mensaje']});
            }

        }
        else {
            toast.current.show({severity: 'error', summary: 'Error Mensaje', detail: 'Falla la conexión con la Api'});
        }


    }

    const onTemplateSelect = (e) => {
          setChooseOptions({
              style: {display: 'none'},
          });
    }
    const onTemplateRemove = (e) => {
        setChooseOptions({
        });
    }
    return (

        <div className="grid dashboard">
            <Toast ref={toast}/>

            <Panel header="Registro defensa de grado" className='col-12'>
                <form id="formRegistroDocumentoEncargado" onSubmit={registrar} encType="multipart/form-data">
                    <div className="col-12 md:col-12 p-3">
                        <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-tag"/>
                        </span>
                            <span className="p-float-label">
                            <InputText required id="in" value={titulo} onChange={(e) => setTitulo(e.target.value)}/>
                            <label htmlFor="in">Titulo</label>
                        </span>
                        </div>
                    </div>
                    <div className="col-12 md:col-12 p-3">
                        <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-info"/>
                        </span>
                            <span className="p-float-label">
                            <InputText required id="in" value={descripcion} onChange={(e) => setDescripcion(e.target.value)}/>
                            <label htmlFor="in">Descripción</label>
                        </span>
                        </div>
                    </div>
                    <div className="col-12 md:col-12 p-3">
                        <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-clock"/>
                        </span>
                            <span className="p-float-label">
                            <InputNumber required inputId="gestion" value={gestion} onValueChange={(e) => setGestion(e.value)} />
                            <label htmlFor="in">Gestión</label>
                        </span>
                        </div>
                    </div>
                    <div className="col-12 md:col-12 p-3">
                        <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-cog"/>
                        </span>
                            <Dropdown required value={modalidad}
                                      onChange={(e) => setModalidad(e.value)}
                                      options={ListModalidadDefensa} optionLabel="label"
                                      placeholder="Seleccione la modalidad de defensa"
                                      showClear={true}
                                      showFilterClear={true}
                                      filter={true}
                            />
                        </div>
                    </div>
                    <div className="col-12 md:col-12 p-3">
                        <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-star"/>
                        </span>
                            <span className="p-float-label">
                            <InputText required id="in" value={tutor} onChange={(e) => setTutor(e.target.value)}/>
                            <label htmlFor="in">Tutor</label>
                        </span>
                        </div>
                    </div>
                    <div className="col-12 md:col-12 p-3">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user-plus"/>
                            </span>
                            <MultiSelect required value={dropdownUsuario}
                                         onChange={(e) => setDropdownUsuario(e.value)}
                                         options={listEstudiante} optionLabel="label"
                                         placeholder="Seleccione el estudiante"
                                         showClear={true}
                                         showFilterClear={true}
                                         filter={true}
                            />
                        </div>
                    </div>
                    <div className="col-12 md:col-12 p-3">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-bolt"/>
                            </span>
                            <Dropdown required value={dropdownCarrera}
                                      options={listCarrera}
                                      onChange={(e) => setDropdownCarrera(e.value)}
                                      placeholder="Seleccione la carrera"
                                      showClear={true}
                                      showFilterClear={true}
                                      filter={true}
                            />
                        </div>
                    </div>
                    <div className="col-12 md:col-12 p-3">
                        <FileUpload disabled={dis} mode={"advanced"} onSelect={onTemplateSelect} name="demo" url={dir} onUpload={onUpload} accept="application/pdf" maxFileSize={100000000}
                                    chooseOptions={chooseOptions} onRemove={onTemplateRemove} onClear={onTemplateRemove} uploadLabel={"Subir"} cancelLabel={"Cancelar"} chooseLabel={"Seleccionar"}
                                    emptyTemplate={<p className="p-m-0">{mensaje}</p>}/>
                    </div>
                    <div className="col-12 md:col-12 p-3">
                        <Button type="submit" label="Registrar" className="p-button-raised"/>
                    </div>
                </form>
            </Panel>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(EncargadoRegistroDocumento, comparisonFn);
