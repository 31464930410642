import React, {useState, useEffect} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import './DataTableDemo.css';
import API,{URLPDF} from "../../service/apiRequest";

const ListarDocumento = () => {
    const [listDocumentos, setListDocumentos] = useState([]);
    const [loading1, setLoading1] = useState(true);
    const ListModalidadDefensa = [
        'PROYECTO DE GRADO',
        'PROYECTO SOCIOCOMUNITARIO PRODUCTIVO',
        'PROYECTO EMPRENDIMIENTO PRODUCTIVO',
        'TRABAJO DIRIGIDO'
    ];
    const listarDocumentos = () => {
        API.get("proyecto/listarDocumentos").then(resp => {
            var list = [];
            var data = resp.data;
            var mod = "";
            var indice = 0;
            data.forEach(element => {
                for (var i = 0; i < ListModalidadDefensa.length; i++) {
                    if (element[2] === i) {
                        mod = ListModalidadDefensa[i];
                    }
                }
                indice++;
                list.push({
                        in: indice,
                        integrantes: element[0],
                        titulo: element[1],
                        modalidad: mod,
                        gestion: element[3],
                        documento: element[4],
                        tutor: element[5],
                        descripcion: element[6],
                        carrera: element[7]
                    }
                );
                setLoading1(false) });
            setListDocumentos(list);
        }).catch(function (err) {
        });
    }
    const documentoTemplate=(rowData) =>{
        return <a rel="noreferrer" className="p-button-sm pi pi-save" href={URLPDF+"resources/uploads/defensas/"+rowData.documento+".pdf"} target={"_blank"}> PDF</a>
    }
    const renderHeader1 = () => {
        return (
            <div className="p-d-flex p-jc-between">
                <h3 style={{textAlign:"center"}}>Lista de proyectos</h3>
            </div>
        )
    }
    useEffect(() => {
        listarDocumentos();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const header1 = renderHeader1();

    return (
        <div className="card">
            <div className="datatable-filter-demo">
            <DataTable
                   filterDisplay="row" loading={loading1}
                     header={header1}  paginator showGridlines rows={10}
                           emptyMessage="No hay datos"
                           value={listDocumentos} responsiveLayout="scroll">
                    <Column field="in" header="Num" sortable/>
                    <Column field="documento" body={documentoTemplate} header="Opciones"/>
                    <Column field="titulo" header="Titulo" showFilterMenu={false} filter filterPlaceholder="Buscar por titutlo" filterMatchMode='contains' style={{ minWidth: '12rem' }}/>
                    <Column field="integrantes" header="Integrantes" showFilterMenu={false} filter filterPlaceholder="Buscar por integrante" filterMatchMode='contains' style={{ minWidth: '12rem' }}/>
                    <Column field="modalidad" header="Modalidad" showFilterMenu={false} filter filterPlaceholder="Buscar por modalidad" filterMatchMode='contains' style={{ minWidth: '12rem' }}/>
                    <Column field="gestion" header="Gestión" showFilterMenu={false} filter filterPlaceholder="Buscar por gestion" filterMatchMode='contains' style={{ minWidth: '12rem' }}/>
                    <Column field="carrera" header="Carrera" showFilterMenu={false} filter filterPlaceholder="Buscar por carrera" filterMatchMode='contains' style={{ minWidth: '12rem' }}/>
                    <Column field="tutor" header="Tutor" showFilterMenu={false} filter filterPlaceholder="Buscar por tutor" filterMatchMode='contains' style={{ minWidth: '12rem' }}/>
                </DataTable>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(ListarDocumento, comparisonFn);
