import React from 'react';
import { Fieldset } from 'primereact/fieldset';

const Dashboard = (props) => {


    return (
        <div className="grid dashboard">
            <Fieldset legend="Información">
                <p>Esta es una aplicacion web de registro de los distintas modalidades de graduacion de la institucion</p>
                            </Fieldset>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
